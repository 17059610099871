import meter1 from "../assets/img/meter1.jpg";
import meter2 from "../assets/img/meter2.jpg";
import meter3 from "../assets/img/meter3.jpg";
import meter4 from "../assets/img/meter4.jpg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";


export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
    
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h4>Dienstleistungen</h4>
                        <p>Wir bieten sämtliche Sicherheitsdienstleistungen an, die ihr Unternehmen, Behörde oder Person benötigt.
<br></br>Der BSB Sicherheitsdienst erstellt für jeden Auftrag ein individuelles Sicherheitskonzept, welches genau auf die Rahmenbedingungen abgestimmt ist.</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={meter1} alt="Image" id="meter"/>
                                <h6>Objektschutz</h6>
                                <p>Sicherheit für Ihr Eigentum. Wir bieten individuelle Lösungen für den Schutz Ihres Eigentums.</p>
                            </div>
                            <div className="item">
                                <img src={meter2} alt="Image" id="meter" />
                                <h6>Pfortendienst</h6>
                                <p>Wir sind der kompetente erste Ansprechpartner für Ihre Kunden, Besucher, Lieferanten und Mitarbeiter.</p>
                            </div>
                            <div className="item">
                                <img src={meter3} alt="Image" id="meter"/>
                                <h6>Detektive</h6>
                                <p>Sicheren Sie Ihr Kaufhaus oder Ladengeschäft zuverlässig gegen Diebstähle.</p>
                            </div>
                            <div className="item">
                                <img src={meter4} alt="Image" id="meter" />
                                <h6>Baustellenbewachung</h6>
                                <p>Ihre Baustelle in guten Händen! Wir bieten professionelle Baustellenbewachung für Ihr Bauprojekt.</p>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
   
    </section>
  )
}
