import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import visitenk from "../assets/img/visitenk.png";
import 'animate.css';

export const Contact = () => {
  const form = useRef();
  const [messageSent, setMessageSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_asr83tr', 'template_4ef5ew9', form.current, 'fudd3FrJJCAf_BSgU')
      .then((result) => {
        console.log(result.text);
        console.log("Message sent");
        setMessageSent(true);  
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <Container id="connect">
      <Row>
        <Col md={12} style={{ marginTop: '60px' }}>
          <h2 style={{ fontSize: '64px', marginBottom: '60px' }}>Angebot anfordern</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form
            ref={form}
            onSubmit={sendEmail}
            style={{
              backgroundColor: '#333333',
              borderRadius: '10px',
              padding: '20px',
            }}
          >
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="user_name"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  border: '1px solid grey',
                  color: 'white',
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="user_email"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  border: '1px solid grey',
                  color: 'white',
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Nachricht</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  border: '1px solid grey',
                  color: 'white',
                  height: '90px',
                }}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              style={{
                marginTop: '60px',
                backgroundColor: '#333333',
                border: '1px solid white',
                color: '#ffde59',
              }}
            >
              Senden
            </Button>
          </Form>
        </Col>
        <Col md={6} style={{ borderRadius: '10px' }}>
          <img src={visitenk} alt="visitenk" style={{ width: '100%', height: '100%' }} />
        </Col>
      </Row>
      {messageSent && (
        <Alert variant="success" className="mt-3">
          <strong>Nachricht versendet</strong>
        </Alert>
      )}
    </Container>
  );
}
